<template>
  <div>
    <RMPageHeader>
      <template v-slot:header>
        {{ $t('mentorship.studentGroups') }}
      </template>
    </RMPageHeader>
    <div class="row justify-content-end m-b-10">
      <b-button v-b-modal="'add-student-group-modal'" variant="primary" class="m-r-10"><i class="fa fa-plus m-r-10" aria-hidden="true"></i>{{ this.$t("mentorship.addStudentGroup") }}</b-button>
    </div>
    <hr/>
    <div v-if="loadingStudentGroups" class="row m-t-20">
      <div class="col-12">
        <b-skeleton class="height-200"></b-skeleton>
      </div>
    </div>
    <div v-else-if="this.studentGroups.length === 0" class="row m-t-20">
      <div class="col-12">
        <b-alert show variant="light" class="text-center" style="font-size: medium">
          {{ $t("mentorship.noStudentGroupsYet") }}
        </b-alert>
      </div>
    </div>
    <div v-else class="row">
      <div class="col-xs-12 col-sm-12 col-md-6 col-xl-4 col-xxl-3 col-xxxl-3"
        v-for="(studentGroup, idx) in studentGroups"
        :key="idx"
      >
        <RMPanel :title="studentGroup.name">
          <template v-slot:panelBody>
            <div class="d-flex justify-content-between m-b-10">
              <span>{{ $t("mentorship.studentGroupInfo.description") }}:</span>
              <div>{{ studentGroup.description }}</div>
            </div>
            <hr/>
            <div class="d-flex justify-content-between m-b-10">
              <span>{{ $t("mentorship.studentGroupInfo.numMembers") }}:</span>
              <div><b>{{ studentGroup.numMembers }}</b></div>
            </div>
            <hr/>
            <div class="d-flex justify-content-between m-b-10">
              <span>{{ $t("mentorship.studentGroupInfo.numAssignments") }}:</span>
              <div><b>{{ studentGroup.numAssignments }}</b></div>
            </div>
<!--            <hr/>-->
<!--            <div class="d-flex justify-content-between m-b-10">-->
<!--              <span>{{ $t("mentorship.studentGroupInfo.createdAt") }}:</span>-->
<!--              <div><b>{{ new Date(studentGroup.createdAt).toLocaleString(getDateTimeLocale()) }}</b></div>-->
<!--            </div>-->
            <hr/>
            <div class="d-flex justify-content-end">
              <b-link @click="navigateToStudentGroupPage(studentGroup.id)">{{ $t("assignments.detailsLink") }}</b-link>
            </div>
          </template>
        </RMPanel>
      </div>
    </div>
    <NewStudentGroupModal @reloadStudentGroups="loadStudentGroups"/>
  </div>
</template>

<script>
import RMPageHeader from "@/components/common/RMPageHeader.vue";
import {mentorshipService} from "@/_services/mentorship.service";
import {handleError} from "@/_helpers/api";
import router from "@/config/PageRoutes";
import NewStudentGroupModal from "@/pages/mentorship/NewStudentGroupModal.vue";
import {getDateTimeLocale} from "@/_helpers/locale_helper";
import RMPanel from "@/components/common/RMPanel.vue";
import {getStudentGroupRoute} from "@/_helpers/routes_helper";

export default {
  name: "StudentGroups",
  data() {
    return {
      loadingStudentGroups: false,
      studentGroups: []
    }
  },
  components: {RMPanel, NewStudentGroupModal, RMPageHeader},
  methods: {
    getDateTimeLocale,
    navigateToStudentGroupPage(id) {
      this.$router.push(getStudentGroupRoute(id));
    },
    loadStudentGroups() {
      this.loadingStudentGroups = true;

      mentorshipService.listStudentGroups().then((data) => {
        this.studentGroups = data.data;
        this.loadingStudentGroups = false;
      }).catch((error) => handleError(error, router, this._vm)).finally(() => {
        this.loadingStudentGroups = false;
      })
    }
  },
  created() {
    this.loadStudentGroups();
  },
}
</script>