<template>
  <b-modal @show="loadMyStudents" v-model="modalVisible" id="add-student-group-modal" size="lg" centered v-bind:title="this.$t('mentorship.addStudentGroup')" header-class="rm-panel-heading">
    <div class="row m-b-10">
      <label class="col-form-label col-md-3 text-lg-right">
        {{ this.$t("mentorship.studentGroupInfo.name") }}
        <span class="text-theme">*</span>
      </label>
      <div class="col-md-6">
        <b-input type="text" v-model="studentGroupName" class="form-control" maxlength="50" size="50"/>
      </div>
    </div>
    <div class="row m-b-10">
      <label class="col-form-label col-md-3 text-lg-right">
        {{ this.$t("mentorship.studentGroupInfo.description") }}
      </label>
      <div class="col-md-6">
        <b-textarea v-model="studentGroupDescription" class="form-control"/>
      </div>
    </div>
    <div class="row">
      <label class="col-form-label col-md-3 text-lg-right">
        {{ this.$t("mentorship.students") }}
        <span class="text-theme"></span>
      </label>
      <div class="col-md-6">
        <RMMultiSelect
          v-bind:options="usersList"
          v-bind:multiple="true"
          @onChange="onUserChange"
        />
      </div>
    </div>
    <div v-if="errorAdding" class="row m-t-20">
      <div class="col-md-3"></div>
      <div class="col-md-6">
        <div class="alert alert-danger" role="alert">
          <i class="fa fa-exclamation-triangle m-r-5" aria-hidden="true"></i>
          <b>{{ $t("mentorship.errorAddingStudentGroup")}}: </b>
          {{ userError }}
        </div>
      </div>
    </div>
    <div v-if="successAdding" class="row m-t-20">
      <div class="col-md-3"></div>
      <div class="col-md-6">
        <div class="alert alert-success text-center" role="alert">
          <i class="fa fa-check m-r-5" aria-hidden="true"></i>
          <span>{{ $t("mentorship.studentGroupAdded") }}</span>
        </div>
      </div>
    </div>
    <template #modal-footer>
      <b-button variant="primary" :disabled="studentGroupName.length < 1 || saving" @click="handleSubmit">{{ $t("mentorship.add") }}</b-button>
      <b-button variant="secondary" :disabled="saving" @click="handleClose">{{ $t("mentorship.cancel") }}</b-button>
    </template>
  </b-modal>
</template>

<script>
import RMMultiSelect from "@/components/features/RMMultiSelect.vue";
import {mentorshipService} from "@/_services/mentorship.service";
import {handleError} from "@/_helpers/api";
import router from "@/config/PageRoutes";

export default {
  name: "NewStudentGroupModal",
  components: {RMMultiSelect},
  data() {
    return {
      selectedUsers: [],
      usersList: [],
      saving: false,
      modalVisible: false,
      studentGroupName: "",
      studentGroupDescription: "",
      errorAdding: false,
      successAdding: false,
      error: "",
    }
  },
  computed: {
    userError() {
      return this.$t("mentorship." + this.error);
    },
  },
  methods: {
    onUserChange(userList){
      this.selectedUsers = userList;
    },
    initData(savingValue) {
      this.studentGroupName = "";
      this.studentGroupDescription = "";
      this.errorAdding = false;
      this.successAdding = false;
      this.forceDisableButton = false;
      this.error = ""
      this.saving = savingValue;
    },
    handleClose() {
      this.modalVisible = false;
      this.initData(false);
    },
    handleSubmit() {
      this.errorAdding = false;
      this.saving = true;

      mentorshipService.addStudentGroup({
        name: this.studentGroupName,
        description: this.studentGroupDescription,
        members: this.selectedUsers.map(x => x.id)
      }).then(() => {
        this.successAdding = true;
        this.$emit("reloadStudentGroups");
        return new Promise(resolve => {
          setTimeout(() => {
            resolve();
          }, 1000);
        });
      })
      .then(() => {
        this.modalVisible = false;
        this.initData(true);
      })
      .catch((error) => {
        this.saving = false;
        this.errorAdding = true;
        this.error = error;
      });
    },
    loadMyStudents() {
      mentorshipService.listMyStudents().then((data) => {
        this.usersList = data.data.map(({id, name, email}) => { return {id, label: `${name} (${email})`}});
      }).catch((error) => handleError(error, router, this._vm)).finally(() => {})
    }
  },
  created() {
    this.initData(false);
  }
}
</script>